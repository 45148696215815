import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const liminaire = () => (
  <Layout>
    <SEO title="Dernières oeillades - Le vainqueur" />
    <h3 className='underline-title'>Le vainqueur</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Je t'entends t'égosiller <br />
        Tu me rabats les oreilles assourdies <br />
        De ta victoire <br />
        <span className="par-starting"></span>victoire première <br />
      </p>
      <p>
        À longueur de journée <br />
        Tu clames ta victoire <br />
        <span className="par-starting"></span>victoire initiale <br />
      </p>
      <p>
        Elle est en fuite <br />
        <span className="par-starting"></span>ta femme légère <br />
        Tu me serines ta victoire <br />
        <span className="par-starting"></span>victoire partielle <br />
      </p>
      <p>
        Recherche ta femme <br />
        <span className="par-starting"></span>elle est en fuite <br />
        <span className="par-starting"></span>ta femme traîtresse <br />
      </p>
      <p>
        Pourquoi claironnes-tu?<br />
        Laisse-moi te rendre hommage <br />
        Recherche plutôt ta femme <br />
        Garce pour qui tu enfreignis <br />
        <span className="par-starting"></span>le cinquième commandement <br />
      </p>
      <p>
        Elle est en fuite <br />
        <span className="par-starting"></span>ta femme adultère <br />
        Sans clairon inutile <br />
        <span className="par-starting"></span>cherche ta femme impudique <br />
      </p>
      <p>
        À force de criailler ta victoire mutilée <br />
        Tu t'alignes au rang des tonneaux <br />
        Tonneaux vidés de leur huile <br />
        <span className="par-starting"></span>huile noble de palme <br />
        Tonneaux roulés sur une route <br />
        <span className="par-starting"></span>route gorgée d'aspérités <br />
      </p>
      <p>
        Retentis de boucan de tous les diables <br />
        Criant leur néant hautain du contenu <br />
        À tout vent du monde morphinomane <br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default liminaire
